import React from "react"
import styled from "styled-components"

// Styling
import textStyles from "../styles/textStyles"
import colors from "../styles/colors"
import mq from "../styles/breakpoints"

// Components
import Seo from "../components/Seo"
import ContentWrapper from "../components/ContentWrapper"
import Link from "../components/atoms/Link"

const NotFoundPage: React.FC = () => (
  <>
    <Seo title="404" indexable={false} dateModified="" datePublished="" />
    <ContentWrapper>
      <Container>
        <Title>404</Title>
        <Subtitle>Nothing to see here...</Subtitle>
        <Text>
          This page doesn't seem to exists (anymore). Let's take you back home.
        </Text>
        <Link
          type="internal"
          to="/"
          title="Let's go home"
          color={colors.purpleLight}
        />
      </Container>
    </ContentWrapper>
  </>
)

const Container = styled.section`
  margin-bottom: 120px;
  padding-top: 100px;

  ${mq.from.S`
    margin-bottom: 240px;
    padding-top: 200px;
  `}
`

const Title = styled.h1`
  ${textStyles.subtitle}
  margin-bottom: 8px;
`

const Subtitle = styled.h2`
  ${textStyles.titleL};
  max-width: 640px;
  margin-bottom: 24px;
`

const Text = styled.p`
  max-width: 640px;
  margin-bottom: 32px;
`

export default NotFoundPage
